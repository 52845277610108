import React from 'react';
import Layout from '../components/layout';
import { Link, graphql, useStaticQuery } from 'gatsby';

import tableStyles from './cards.module.scss'

export const query = graphql`
    query {
        allHorsesCsv {
            group(field: race_num) {
              fieldValue
              nodes {
                HORSE
                first__
                mu
                number
                draw_x
                race_num
                age_x
                layoffs
              }
            }
          }
    }
`

const Horses = (props) => {

    return (
        <Layout>
            <h1 style = {{textAlign: 'center'}}>Hong Kong Horse Racing Predictions</h1>
            <p style = {{textAlign: 'center', paddingLeft: '10%', paddingRight: '10%'}}>
                Updated regularly for new race meetings.  Each card represents a race during a specific meeting.  There are handicapping factors available as well.
                The handicapping factors, include, draw, layoff and class ratings.  The combination of these and many other handicapping factors including past 
                performance data produce the win probabilities that are listed alongside each horse
            </p>
            <hr></hr>
                <h3>SHA TIN - 2021-03-30</h3>
                {props.data.allHorsesCsv.group.map((dt) => {
                    return(
                        <div className={tableStyles.accordion}>
                            <div className={tableStyles.accordion__single}>
                            <input className={tableStyles.accordion__single__hidden} type = "checkbox" id = {dt.fieldValue}></input>
                            <label className={tableStyles.accordion__single__question} for={dt.fieldValue}>Race number {dt.fieldValue}</label>
                            <div className={tableStyles.accordion__single__answer}>
                            <table className={tableStyles.horseTable}>
                                <thead>
                                    <tr>
                                        <th>horse</th>
                                        <th>age</th>
                                        <th>number</th>
                                        <th>draw</th>
                                        <th>layoff</th>
                                        <th>prob</th>
                                        <th>class</th>
                                    </tr>
                                </thead>
                                {dt.nodes.map((game) => {
                                return(
                                    <tbody>
                                        <tr>
                                            <td>{game.HORSE}</td>
                                            <td>{game.age_x}</td>
                                            <td>{game.number}</td>
                                            <td>{game.draw_x}</td>
                                            <td>{game.layoffs}</td>
                                            <td>{game.first__}</td>
                                            <td>{game.mu}</td>
                                        </tr>
                                    </tbody>
                                )
                            })}
                            </table>
                            </div>
                        </div>
                        </div>
                    )
                })}
        </Layout>
    )
}

export default Horses